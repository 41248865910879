import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Paper, ThemeProvider } from '@mui/material';
import DefaultTheme from './utils/Theme';
import { useConfiguredAxios } from './utils/AxiosInstance';
import Header from './components/Header';
import Main from './pages/Main';
import Error from './pages/Error';
import Wrapper from './contexts/Wrapper';
import { Tabs } from './contexts/NavProvider';

const App: React.FC = () => {
  const axiosInstance = useConfiguredAxios();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axiosInstance.get('/api/auth/status');
        const data = response.data as { isAuthenticated: boolean };

        if (!data) {
          setError(true);
          return;
        }

        if (!data.isAuthenticated) {
          const authUrl = process.env.NODE_ENV === 'production'
            ? 'https://getzlaf.plessinc.com/api/auth/google'
            : 'http://localhost:8000/api/auth/google';
          window.location.replace(authUrl);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error: any) {
        console.error('Error fetching auth status:', error);
        setError(true);
      }
    };

    checkAuthStatus();
  }, [axiosInstance]);

  return (
    <ThemeProvider theme={DefaultTheme()}>
      <CssBaseline/>
      {isAuthenticated ? (
        <Wrapper>
          <Paper elevation={0} sx={{height: '100vh', width: '100%', borderRadius: 0}}>
            <Header/>
            <Router>
              <Routes>
                {Tabs.map((tab, index) => (
                  <Route key={tab.name} path={tab.path} element={<Main page={index}/>}/>
                ))}
                <Route path="/*" element={<Main page={0}/>}/>
              </Routes>
            </Router>
          </Paper>
        </Wrapper>
      ) : (
        <Error error={error}/>
      )}
    </ThemeProvider>
  );
};

export default App;
