// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

// ----- MUI ----- //
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----- Components ----- //
import CardComponent from "../../components/CardComponent";

// ----- Utils ----- //
import { EventType } from "../../utils/Types";
import { CancelledEventsContext } from "../../contexts/CancelledEventsProvider";
import { enqueueSnackbar } from "notistack";
import { CustomDataGrid } from "../../utils/Theme";
import { ValueFormatter } from "../../utils/ValueFormatter";

const Payments = () => {
  // ----- Context ----- //
  const { getCancelledEvents, loading } = useContext(CancelledEventsContext);

  // ----- Theme ----- //
  const theme = useTheme();

  // ----- States ----- //
  const [cancelledEvents, setCancelledEvents] = useState<EventType[]>([]);

  // ----- Columns ----- //
  const columns = useMemo(
    () => [
      { field: "url_id", headerName: "URL ID", width: 200,
        renderCell: (params: any) => (
          <Button variant="text" href={params.row.url} target="_blank" rel="noreferrer">
            {params.value}
          </Button>
        ) },
      { field: "name", headerName: "Name", width: 200 },
      { field: "event_date", headerName: "Event Date", width: 200,
        valueFormatter: (value: any) => ValueFormatter.date(value, true) },
      { field: "timezone", headerName: "Timezone", width: 200 },
      { field: "venue_id", headerName: "Venue ID", width: 200 },
      { field: "created_at", headerName: "Created At", width: 200,
        valueFormatter: (value: any) => ValueFormatter.date(value, true) },
      { field: "updated_at", headerName: "Updated At", width: 200,
        valueFormatter: (value: any) => ValueFormatter.date(value, true) },
    ],
    []
  );

  // ----- Fetch Data ----- //
  const fetchCancelledEvents = useCallback(async () => {
    try {
      const data = await getCancelledEvents();
      setCancelledEvents(data);
    } catch (error) {
      console.error("Error fetching cancelled events:", error);
      enqueueSnackbar("Failed to load cancelled events.", { variant: "error" });
    }
  }, [getCancelledEvents]);

  useEffect(() => {
    fetchCancelledEvents();
  }, []);

  // ----- Render ----- //
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        flexWrap: "wrap",
        ml: 1.5,
        mb: 2,
        justifyContent: { xs: "center", sm: "flex-start" },
      }}
    >
      <CardComponent title="Cancelled Events" color={theme.palette.error.main} defaultHeight="calc(100vh - 200px)">
          <CustomDataGrid
            rows={cancelledEvents}
            columns={columns}
            density="compact"
            loading={loading}
            pagination
            pageSizeOptions={[]}
            slotProps={{
              loadingOverlay: {
                variant: "skeleton",
                noRowsVariant: "skeleton",
              },
            }}
            disableAggregation
            disableRowGrouping
            headerFilters
          />
      </CardComponent>
    </Box>
  );
};

export default Payments;
