// ----- Modules ----- //
import React, { ReactNode, useCallback, useEffect, useState } from "react";

// ----- Components ----- //
import Payments from "../pages/payments/Payments";
import Dashboard from "../pages/dashboard/Dashboard";
import PurchaseOrders from "../pages/purchaseOrders/PurchaseOrders";
import Transactions from "../pages/transactions/Transactions";
import Tickets from "../pages/tickets/Tickets";
import TicketsInfo from "../pages/tickets/TicketsInfo";
import Invoices from "../pages/invoices/Invoices";
import Emails from "../pages/emails/Emails";
import FetchLogs from "../pages/FetchLogs";

// ----- MUI Icons ----- //
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import StadiumIcon from '@mui/icons-material/Stadium';
import CancelledEvents from "../pages/events/CancelledEvents";

// ----- Utils ----- //
export const Tabs = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    main: <Dashboard/>,
    info: null,
    icon: <DashboardIcon/>,
  },
  {
    name: 'Payments',
    path: '/payments',
    main: <Payments/>,
    info: null,
    icon: <PaymentsIcon/>,
  },
  {
    name: 'Invoices',
    path: '/invoices',
    main: <Invoices/>,
    info: null,
    icon: <CreditCardIcon/>,
  },
  {
    name: 'Tickets',
    path: '/tickets',
    main: <Tickets/>,
    info: <TicketsInfo/>,
    icon: <ConfirmationNumberIcon/>,
  },
  {
    name: 'Transactions',
    path: '/transactions',
    main: <Transactions/>,
    info: null,
    icon: <ReceiptIcon/>,
  },
  {
    name: 'Purchase order',
    path: '/purchases',
    main: <PurchaseOrders/>,
    info: null,
    icon: <ShoppingCartIcon/>,
  },
  {
    name: 'Events',
    path: '/events',
    main: <CancelledEvents/>,
    info: null,
    icon: <StadiumIcon/>,
  },
  {
    name: 'Emails',
    path: '/emails',
    main: <Emails/>,
    info: null,
    icon: <EmailIcon/>,
  },
  {
    name: 'Logs',
    path: '/fetch-info',
    main: <FetchLogs/>,
    info: null,
    icon: <InfoIcon/>,
  }
];

interface NavContextInt {
  tab: number;
  tabs: typeof Tabs;
  handleTabChange: (tab: number) => void;
}

interface NavProviderProps {
  children: ReactNode;
}

const NavContext = React.createContext<NavContextInt>({
  tab: 0,
  tabs: Tabs,
  handleTabChange: () => {},
});

const NavProvider: React.FC<NavProviderProps> = ({children}) => {
  // ----- States ----- //
  const [tab, setTab] = useState(() => {
    const initialTab = Tabs.findIndex((t) => t.path === window.location.pathname);
    return initialTab > -1 ? initialTab : 0;
  });

  const handleTabChange = useCallback((tab: number) => {
    setTab(tab);
    window.history.pushState(null, '', Tabs[tab].path);
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const currentTab = Tabs.findIndex((t) => t.path === window.location.pathname);
      if (currentTab > -1) setTab(currentTab);
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // ----- Render ----- //
  return (
    <NavContext.Provider value={{tab, tabs: Tabs, handleTabChange}}>
      {children}
    </NavContext.Provider>
  );
};

export { NavContext, NavProvider };
