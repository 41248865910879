// ----- Modules ----- //
import React from 'react';

// ----- MUI ----- //
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// ----- Providers ----- //
import { closeSnackbar, SnackbarProvider } from "notistack";
import { TicketsProvider } from "./TicketsProvider";
import { NavProvider } from "./NavProvider";
import { SalesProvider } from "./SalesProvider";
import { InvoicesProvider } from "./InvoicesProvider";
import { PaymentsProvider } from "./PaymentsProvider";
import { MarketsProvider } from "./MarketsProvider";
import { TransactionsProvider } from "./TransactionsProvider";
import { PurchaseOrdersProvider } from "./PurchaseOrdersProvider";
import { DsSalesProvider } from "./DsSalesProvider";
import { CancelledEventsProvider } from "./CancelledEventsProvider";

// ----- Wrapper Component ----- //
const Wrapper = ({children}: { children: React.ReactNode }) => {

  const snackbarAction = (snackbarId: string) => (
    <IconButton onClick={() => closeSnackbar(snackbarId)} size="small">
      <CloseIcon/>
    </IconButton>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarProvider
        action={snackbarAction as any}
        dense
        maxSnack={5}
        preventDuplicate
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MarketsProvider>
          <TicketsProvider>
            <SalesProvider>
              <InvoicesProvider>
                <DsSalesProvider>
                  <PaymentsProvider>
                    <TransactionsProvider>
                      <PurchaseOrdersProvider>
                        <CancelledEventsProvider>
                          <NavProvider>
                            {children}
                          </NavProvider>
                        </CancelledEventsProvider>
                      </PurchaseOrdersProvider>
                    </TransactionsProvider>
                  </PaymentsProvider>
                </DsSalesProvider>
              </InvoicesProvider>
            </SalesProvider>
          </TicketsProvider>
        </MarketsProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default Wrapper;
