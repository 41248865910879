// ----- Modules ----- //
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
// ----- MUI ----- //
import { Box, IconButton, LinearProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// ----- Utils ----- //
import { formatPaymentData, parseCsv, parseXlsx } from './utils/fileProcessing';
import { extractPaymentInfo, getMarketName } from './utils/infoUtils';
import DataGridModal from './DataGridModal';
import { useConfiguredAxios } from "../../utils/AxiosInstance";
import { enqueueSnackbar } from "notistack";
import { PaymentsContext } from "../../contexts/PaymentsProvider";
import { PaymentLineType, PaymentType } from "../../utils/Types";

// ----- Constants ----- //
const markets = [
  {id: 3438867, name: 'FanXchange', unique: false},
  {id: 220566, name: 'Ticket Network', unique: false},
  {id: 3499490, name: 'Billet.ca', unique: true},
  {id: 2988231, name: 'GameTime', unique: false},
  {id: 4443058, name: 'TM Resale', unique: true},
  {id: 4450631, name: 'Tn Mercury', unique: false},
];

interface CsvImportComponentProps {
  marketId: number;
}

const PaymentImportComponent: React.FC<CsvImportComponentProps> = ({ marketId }) => {
  const axiosInstance = useConfiguredAxios();
  const { getPaymentsByMarkets } = useContext(PaymentsContext);

  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);
  const [parsedPayment, setParsedPayment] = useState<PaymentType | null>(null);
  const [status, setStatus] = useState<string>('No files uploaded');
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > 0) {
      setFiles(selectedFiles);
      setCurrentFileIndex(0);
      processNextFile(selectedFiles[0]);
    }
  };

  const processNextFile = async (file: File) => {
    setIsProcessing(true);
    setLoading(true);
    setStatus(`Processing ${file.name}...`);
    try {
      const { paymentDate, paymentId, account, isAdjustments } = await extractPaymentInfo(file.name, marketId);

      if (!isAdjustments && (!paymentDate || !paymentId) && !markets.find((market) => market.id === marketId)?.unique) {
        throw new Error('Could not extract payment date or unique ID');
      }

      const parsedData = await parseFile(file);
      if (!parsedData) throw new Error('Failed to parse file');

      const { payment } = formatPaymentData(
        parsedData,
        marketId,
        paymentDate + '',
        paymentId + '',
        account,
        isAdjustments
      );
      await updatePaymentLinesWithCount(payment.payment_lines, marketId);

      setParsedPayment(payment);
      setModalOpen(true);
    } catch (error: any) {
      enqueueSnackbar(`Error processing ${file.name}: ${error.message}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const parseFile = async (file: File): Promise<any[] | null> => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'csv') {
      return await parseCsv(file, setStatus);
    } else if (fileExtension === 'xlsx') {
      return await parseXlsx(file, setStatus);
    } else {
      setStatus('Unsupported file format');
      return null;
    }
  };

  const updatePaymentLinesWithCount = async (
    paymentLines: PaymentLineType[],
    marketId: number
  ) => {
    try {
      const linesInfo = await axiosInstance
        .post('/api/payments/lines-count', {
          lines: paymentLines,
          marketId,
        })
        .then((res) => res.data);

      paymentLines.forEach((line) => {
        const match = linesInfo.find(
          (lineInfo: any) =>
            line.order_id === lineInfo.order_id &&
            line.payment_id === lineInfo.payment_id &&
            line.market_id === lineInfo.market_id &&
            line.amount === lineInfo.amount
        );
        if (match) line.count = match.count;
      });
      paymentLines.sort((a, b) => (a.count || 0) - (b.count || 0));
    } catch (error) {
      enqueueSnackbar('Failed to update line counts.', { variant: 'error' });
    }
  };

  const handleAcceptData = async (rows: PaymentLineType[]) => {
    try {
      setLoading(true);
      const toAdd = { ...parsedPayment, payment_lines: rows };
      const response = await axiosInstance
        .post('/api/payments/add', { marketId, payment: toAdd })
        .then((res) => res.data);

      enqueueSnackbar(response.paymentAdded ? 'Payment added successfully.' : 'Payment already exists.', {
        variant: response.paymentAdded ? 'success' : 'info',
      });

      const infoMessage = response.linesAdded.length > 0
        ? 'Some lines were added.'
        : 'No new lines were added.';
      enqueueSnackbar(infoMessage, { variant: 'info' });

      handleCloseModal();
      if (currentFileIndex + 1 < files.length) {
        setCurrentFileIndex((prev) => prev + 1);
        processNextFile(files[currentFileIndex + 1]);
      } else {
        await getPaymentsByMarkets('', '', true);
        setStatus('All files processed successfully.');
        setFiles([]);
      }
    } catch (error) {
      enqueueSnackbar('Error adding payment.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setParsedPayment(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <input
        ref={fileInputRef}
        accept=".csv, .xlsx"
        style={{ display: 'none' }}
        type="file"
        multiple
        onChange={handleFileUpload}
        disabled={isProcessing}
      />

      {loading && <LinearProgress sx={{ width: '100px', mr: 1 }} />}

      <IconButton
        color="primary"
        aria-label="upload csv or xlsx"
        component="span"
        disabled={isProcessing}
        onClick={() => fileInputRef.current?.click()}
      >
        <UploadFileIcon fontSize="small" />
      </IconButton>

      <DataGridModal
        loading={loading}
        open={modalOpen}
        onClose={handleCloseModal}
        data={parsedPayment?.payment_lines || []}
        onAccept={handleAcceptData}
        marketName={getMarketName(marketId, markets)}
        fileName={files[currentFileIndex]?.name || ''}
        status={status}
      />
    </Box>
  );
};

export default PaymentImportComponent;
