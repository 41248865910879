// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { EventType } from "../utils/Types";
import { enqueueSnackbar } from "notistack";

interface CancelledEventsContextInt {
  getCancelledEvents: () => Promise<EventType[]>;
  loading: boolean;
  error: boolean;
}

interface CancelledEventsProviderProps {
  children: ReactNode;
}

const CancelledEventsContext = React.createContext<CancelledEventsContextInt>({
  getCancelledEvents: async () => [],
  loading: false,
  error: false,
});

const CancelledEventsProvider: React.FC<CancelledEventsProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // ----- Functions ----- //
  const fetchCancelledEvents = useCallback(async (): Promise<EventType[]> => {
      try {
        console.log("fetching cancelled events");
        return await axiosInstance.get(`/api/events/cancelled/`)
          .then((res) => res.data);
      } catch (error) {
        enqueueSnackbar("Failed to fetch sales data.", {variant: "error"});
        throw error;
      }
    },
    [axiosInstance]
  );

  const getCancelledEvents = async (): Promise<EventType[]> => {
    setLoading(true);
    setError(false)

    try {
      return await fetchCancelledEvents();
    } catch (error) {
      setError(true);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // ----- Render ----- //
  return (
    <CancelledEventsContext.Provider value={{getCancelledEvents, loading, error}}>
      {children}
    </CancelledEventsContext.Provider>
  );
};

export { CancelledEventsContext, CancelledEventsProvider };
